<template>
  <div class="container topCon">
    <el-tabs class="tabs" v-model="activeName">
      <!-- <template v-if="isMkt"> -->
        <!-- <el-tab-pane  label="我的统计" name="first"><my-statistics :type="activeName == 'first' && 1" v-if="activeName == 'first'" /></el-tab-pane> -->
        <!-- <el-tab-pane v-if="userInfo.userType && (userInfo.userType == 1 || userInfo.userType == 2)" label="企业统计" name="second"><enterprise :type="activeName == 'second' && 2" v-if="activeName == 'second'" /></el-tab-pane> -->
      <!-- </template> -->
      <el-tab-pane v-if="isMkc" label="扫码统计" name="third"><scan-statistics :type="activeName == 'third' && 1" v-if="activeName == 'third'" /></el-tab-pane>
    </el-tabs>
    <div v-if="isMkc &&!isMkt && expireTime" class="expireTime">服务到期时间：{{expireTime}}</div>
    <el-button class="exportBtn" @click="handleExport">导出扫码数据</el-button>
    <!--导出扫码数据弹窗-->
    <el-dialog
      title="导出筛选条件"
      width="600px"
      ref="form"
      :modal-append-to-body="false"
      :visible.sync="exportDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
        <el-form-item prop="exportTime" label="时间">
          <el-date-picker
            v-model="ruleForm.exportTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="source" label="类型">
          <el-select v-model="ruleForm.source" placeholder="请选择类型" clearable>
            <el-option
              v-for="item in TypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button class="btncss" @click="handleConfirmExport()" >确 定</el-button>
      </div>
    </el-dialog>

<!--    商户提示框-->
    <el-dialog title="提示"
               width="600px"
               :modal-append-to-body="false"
               :visible.sync="machDialogVisible"
               :close-on-click-modal="false">
      <div class="word">
        您当前还未申请微信支付商户，提现、货款提现额度将被限制为<span style="color: red">2到200元</span>，申请完商户认证即可解除此限制。
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="toApply">去申请</el-button>
        <el-button class="btncss"  @click="toClose">我知道了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import myStatistics from './components/myStatistics';
// import enterprise from './components/enterprise';
import scanStatistics from './components/scanStatistics';
import {queryEmpRouter} from '@/api/companyManage';
import { mapState,mapMutations } from 'vuex';
import { fetchExportAllData } from '@/api/scanStatics'
import {isApplyInfo} from '@/api/wxMerchant'
import { formatDate } from '@/utils/utils.js'
export default {
  name: "dashboard",
  components:{
    scanStatistics
  },
  data() {
    let info = JSON.parse(localStorage.getItem('info'));
    return {
      machDialogVisible: false,
      userInfo: this.$store.state.loginRoot.userInfo,
      activeName:JSON.parse(info.isMkc)?'third':'first',
      jurisdiction:'',
      isMkt: JSON.parse(info.isMkt) ? true: false,
      isMkc: JSON.parse(info.isMkc)? true: false,
      // isMkc: true
      exportDialogVisible: false,
      ruleForm: {
        exportTime: null,
        source: '',
      },
      TypeOptions: [

        {
          value: 1,
          label: '宣传物料（海报、彩页、手册、手提袋、纸巾包、钥匙扣等）'
        },
        {
          value: 2,
          label: '产品'
        },
        {
          value: 3,
          label: '箱标'
        },
        {
          value: 4,
          label: '证书'
        },
        {
          value: 5,
          label: '盒标'
        },
      ],
      exportTableData: [],
      expireTime: '', // 到期时间
    };

  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdiction = val.includes('00')
    }
  },

  created(){
    // console.log('1111',this.$router.history.current.query)
    if(this.$router.history.current.query.isSourceAdmin) {
      localStorage.setItem('isSourceAdmin', this.$router.history.current.query.isSourceAdmin);
    }
    this.initTips()
    if(localStorage.getItem('info')) {
      this.expireTime = formatDate(JSON.parse(localStorage.getItem('info')).expireTime)
    }
  },
  methods:{
    initTips(){
      // if (this.$router.history.current.query.isSourceAdmin || this.$store.state.loginRoot.userInfo.companyList[0].haveMchId || !this.$store.state.loginRoot.userInfo.isMkt){
      //   this.machDialogVisible = false
      // }else {
      //   if (!localStorage.getItem("popped")||localStorage.getItem("popped")==''){ // popped 则赋给他一个值（此时弹框显示）
      //     localStorage.setItem("popped",'yes');
      //   }else{
      //     this.machDialogVisible = false;
      //   }
      // }
    },
    toClose(){
      this.machDialogVisible=false
    },
    // ...mapMutations(['setRouterArr','setRouterArr']),
    // handleClick(tab){
    //   const { name } = tab;
    //   this.activeName = name;
    // },
    // empRouter(){
    //   queryEmpRouter({a:''})
    //   .then(res=>{
    //     let arr = JSON.parse(res.data.routerId)
    //     this.setRouterArr(arr)
    //     this.setEmpId(res.data.empId)
    //     console.log(this.$store.state)
    //   })
    // }
    handleExport() {
      this.exportDialogVisible = true
    },
    async handleConfirmExport() {
      console.log(this.ruleForm)
      let params = {}
      if (this.ruleForm.exportTime) {
        params.startTime = this.ruleForm.exportTime[0]
        params.endTime = this.ruleForm.exportTime[1]
      }
      if (this.ruleForm.source) {
        params.source = this.ruleForm.source
      }
      const loading = this.$loading({
        lock: true,
        text: '下载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fetchExportAllData(params).then(result => {
        // if(result.data) {
        //   this.export2Excel(result.data)
        // }else {
        //   this.$message({
        //     type: "success",
        //     message: "暂无数据",
        //   });
        // }
        let ss = decodeURIComponent(result.fileName)
        let aa = ss.indexOf('=')
        let fileNmae = ss.substring(aa + 1)
        this.downloadBlobFile(result.data, fileNmae, 'text/plain;charset=utf-8')
        loading.close();
        this.closeDialog();
      })
    },
    downloadBlobFile(data, name, type) {
      if (window.navigator.msSaveOrOpenBlob) {
        // 兼容IE
        try {
          var blobObject = new Blob([encodeURI(data)]);
          window.navigator.msSaveOrOpenBlob(blobObject, fileName);
        } catch (e) {
          console.log(e);
        }
      } else {
        var blob = new Blob([data], {
          // type: "application/octet-stream;charset=UTF-8"
          type: type
        });
        var downloadElement = document.createElement("a");
        // 下载的⽂件名
        downloadElement.download = name;
        // 创建下载的链接
        downloadElement.href = window.URL.createObjectURL(blob);
        // 点击下载
        downloadElement.click();
        document.body.appendChild(downloadElement);
        // 下载完成移除元素
        document.body.removeChild(downloadElement);
        // 释放掉blob对象
        window.URL.revokeObjectURL(downloadElement.href);
      }
    },
    closeDialog() {
      this.exportDialogVisible = false
      this.ruleForm.exportTime=null
      this.ruleForm.source=""
    },
    export2Excel(arr) {
      console.log('tableData', arr);
      require.ensure([], () => {
          const {export_json_to_excel} = require('../../excel/Export2Excel.js');
          const tHeader = ['类型', '标识编号', '扫码时间','地址','客户','扫码状态'];
          // 上面设置Excel的表格第一行的标题
          const filterVal = ['typeName', 'handle', 'createTime', 'address', 'nickName','warningCode'];
          // 上面的index、nickName、name是tableData里对象的属性
          var list = arr
          const data = this.formatJson(filterVal, list);
          var tname = "扫码统计_" + new Date().getTime() + '.xlsx'
          export_json_to_excel(tHeader, data, tname);
          this.closeDialog()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map((j) => {
          return v[j]
      }))
    },
    //去申请微信商户
    toApply(){
      isApplyInfo().then(response=>{
        if (response.code == 200){
          if (response.data){
            this.$router.push({ path: "/Merchant" });
          }else {
            this.$router.push({ path: "/merchantApply" });
          }
        }
      })
    },
    // downloadBlobFile(res) {
    //      let blob = new Blob(["\ufeff", res], {
    //         type: "text/csv,charset=UTF-8",
    //       });

    //       if (!!window.ActiveXObject || "ActiveXObject" in window) {
    //         window.navigator.msSaveOrOpenBlob(blob, "fileName.csv");
    //       } else {
    //         const link = document.createElement("a");
    //         link.style.display = "none";
    //         link.href = URL.createObjectURL(blob);
    //         link.setAttribute("download", "fileName.csv");
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //       }
    //       let link = document.createElement("a");
    //       let url = window.URL.createObjectURL(res);
    //       link.href = url;
    //       link.download = "数据统计.xls";

    //       link.click();
    //       window.URL.revokeObjectURL(url);
    // },

  }
};
</script>

<style scoped>
.word{
  text-indent:0.7cm;
  font-size: 16px;
  line-height: 25px;

}
/deep/.el-tabs__header{
  margin-bottom: 16px !important;
}
.topCon{
  position: relative;

}
.exportBtn{
  position: absolute;
  right: 10px;
  top: 10px;
}
.expireTime{
  position: absolute;
  right: 150px;
  top:15px;
  color: #51cbcd;
}
</style>
