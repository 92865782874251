import httpUtil from "@/utils/httpUtil";


/**实时数据统计 */
export const getTimeScan = async params => httpUtil.post("/app/actualCustomLog", params,'',true);

/**扫码人次 */
export const scanStaticTotal =  async params => httpUtil.post("/app/scanStatistics", params);


/**折线图 */
export const sevenData = params => httpUtil.post("/dataCount/sevenData", params);

/**漏斗数据 */
export const getChangeCount = params => httpUtil.post("/seeRecord/getChangeCount", params);

/** 扫码地域排行 */
export const getScanAreaData = params => httpUtil.post("/app/scanAreaDistribution", params);

/** 地图统计数据 */
export const getMapData = params => httpUtil.post("/app/mapStatistics", params);
/** 实时地图数据 */
export const getTimeMapData = params => httpUtil.post("/app/smallMap", params);

/**扫码统计二级页**/
export const fetchScanDetail = params => httpUtil.post("/app/pcInfo", params);

/***扫码量、验证量折线图**/
export const fetchLineChartData = params => httpUtil.post("/app/brokenLine", params);

/***实时用户数**/
export const fetchActualData = params => httpUtil.post("/app/actualData", params);
/***扫码类别**/
export const fetchScanInfoData = params => httpUtil.post("/app/scanInfo", params);
/***扫码类别**/
export const fetchScanInfoTopData = params => httpUtil.post("/app/scanInfoTop", params);
/***产品扫码量Top5**/
export const fetchProdTopData = params => httpUtil.post("/app/productScanTop", params);
/***产品销量Top5**/
export const fetchGoodTopData = params => httpUtil.post("/app/goodsSalesTop", params);
/***用户排名Top5**/
export const fetchUserTopData = params => httpUtil.post("/app/userBuyTop", params);

/***销售额详情 */
export const fetchSaleList = params => httpUtil.post("/app/productSaleInfo", params);
/***购买量详情 */
export const fetchUserBuyList = params => httpUtil.post("/app/userBuyInfo", params);
/***总访问次数详情 */
export const fetchAllScanInfo = params => httpUtil.post("/app/allScanInfo", params);
/***总访问人数详情 */
export const fetchAllScanPeopleInfo = params => httpUtil.post("/app/allScanPeopleInfo", params);
/***总访问区域详情 */
export const fetchAllScanAreaInfo = params => httpUtil.post("/app/allScanAreaInfo", params);
/***扫码地域分页 */
export const fetchScanAreaData = params => httpUtil.post("/app/scanAreaDistributionInfo", params);
/****统计人次单模块 累计区域/今日区域 详情 */
export const fetchScanAreaNumDetail = params => httpUtil.post("/app/scanAreaNumInfoPc", params);
/****统计人次单模块 累计人次/今日人次 详情 */
export const fetchScanPersonNumDetail = params => httpUtil.post("/app/scanPersonNumInfo", params);

/***首页购买统计 */
export const fetchBuyData = params => httpUtil.get("/orders/buyCityDetail", params);

/***首页导出扫码数据 ***/
export const fetchExportAllData = params => httpUtil.get("/customPage/customLogExport", params);

/*** 警告通知*/
export const fetchWaringData = params => httpUtil.post("/app/warnListPage", params);




