<template>
  <div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="allScanInfoDialogVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="80%"
    >

      <commonTable
          :tableData="tableData"
          :loading="loading"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :currentPage="pageNum"
          :total="total"
      >
        <template v-slot:table>
           <el-table-column prop="title" align="center" label="编号">
              <template slot-scope="scope">
                  <span class="serialNumber">{{scope.row.serialNumber? scope.row.serialNumber : '---'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="title" align="center" label="产品">
              <template slot-scope="scope">
                <span>{{scope.row.title? scope.row.title : '---'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="handle" align="center" label="标识" width="200">
              <template slot-scope="scope">
                <span>{{scope.row.handle? scope.row.handle : '---'}}</span>
                <span class="tag-read" :data-clipboard-text="cHandle"  @click="copyHandle(scope.row.handle)"><img src="../../../../assets/images/fz.png" /></span>
              </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="客户">
              <template slot-scope="scope">
                <img :src="scope.row.avatarUrl" class="avatar" /><span>{{ scope.row.nickeName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="time" align="center" label="访问时间">
              <template slot-scope="scope">
                {{ scope.row.time? scope.row.time : '---' }}
              </template>
            </el-table-column>
            <el-table-column prop="time" align="center" label="访问市区">
              <template slot-scope="scope">
                {{ scope.row.address? scope.row.address : '---' }}
              </template>
            </el-table-column>
            <el-table-column prop="address" align="center" label="浏览时长">
              <template slot-scope="scope">
                <template v-if="scope.row.seeTime">
                  <span class="time_span" :class="getTimeColor(scope.row.seeTime)"> 
                    {{ getTimeText(scope.row.seeTime)}}
                  </span>
                </template>
                <template v-else>
                  ---
                </template>
              </template>
            </el-table-column>
          </template>
          
      </commonTable>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import commonTable from "@/components/common/commonTable";
import { fetchAllScanInfo } from "@/api/scanStatics"
export default {
  name: "prodDialog",
  components: {
    commonTable
  },
  data() {
    return {
      allScanInfoDialogVisible: false,
      tableData: [],
      loading: false,
      pageSize: 10,
      pageNum: 1,
      total: 1,
      dialogTitle: '总访问次数详情',
      typeNums: null, // 区分类型
      paramsId: null,
      cHandle: '',
    }
  },
  methods: {
    changeMoney(num) {
      var regexp = /(?:\.0*|(\.\d+?)0+)$/
      if (num > 1000000) {
        num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
        return num + 'w'
      } else {
        num = (num / 100).toFixed(2)
        num = num.replace(regexp, '$1')
        return num
      }
    },
    openDialog(typeNums, data) {
      this.pageNum = 1
      this.pageSize = 10,
      this.typeNums = typeNums
      this.paramsId = data.id
      this.getTableData()
    },
    closeDialog() {
      this.allScanInfoDialogVisible = false
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getTableData()
    },
    handleCurrentChange(num){
      this.pageNum = num
      this.getTableData()
    },
    getTableData() {
      // params 0名片 1宣传物料 2产品 3箱标 4证书
      // typeNums 1产品 2箱标 3物料 4名片 5证书
      let params={
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      if(this.typeNums === 4) {
        params.cardId = this.paramsId
        params.source = 0
      }else {
        if(this.typeNums === 1) {
         params.source = 2
        }else if(this.typeNums === 2) {
          params.source = 3
        }else if(this.typeNums === 3) {
          params.source = 1
        }else if(this.typeNums === 5) {
          params.source = 4
        }
        params.customPageId = this.paramsId
      }
      fetchAllScanInfo(params).then(res => {
        if(!this.allScanInfoDialogVisible) {
          this.allScanInfoDialogVisible = true
        }
        this.tableData = res.data.list
        this.total = res.data.total
        this.pageNum = res.data.pageNum
      })
    },
    // 获取浏览时长class颜色
    getTimeColor(time){
        let colorArr = ['grey','blue','orange']
        if(time && time < 60){
            return colorArr[0]
        }else if(time && time >= 60 && time < 600 ) {
            return colorArr[1]
        }else if(time >= 600) {
            return colorArr[2]
        }
    },
    getTimeText(time) {
      if(time && time < 600){
        return time + 's'
      }else if(time >= 600) {
        return '600+'
      }else {
        return '-'
      }
    },
    // 复制
    copyHandle(data) {
        this.cHandle = data // data.customPageId
        var clipboard = new Clipboard('.tag-read')
        clipboard.on('success', e => {
          console.log('复制成功')
          
        })
        clipboard.on('error', e => {
            // 不支持复制
            console.log('该浏览器不支持复制')
            // 释放内存
            // clipboard.listener.destory()
            this.$message({
              type: 'warning',
              message: '该浏览器不支持复制'
            });
        })
        this.$message({
          type: 'success',
          message: '复制成功'
        });
    },
  },
  filters: {
  },
  mounted() {


  }
}
</script>

<style scoped>
.avatar{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.timeShowTit {
  font-size: 12px;
  background-color: #62baff;
  color: #fff;
  border-radius: 4px;
  height: 25px;
  padding: 3px 8px;
  line-height: 25px;
  margin: auto;
}
.jiangImg{
  height: 30px;
  width: 26px;
}
.time_span{
  padding: 2px 14px;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
}
.grey{
      background-color: #EAEFFF;
      color: #4A7AF0;
  }
.blue{
      background-color: #8EABFF;
      color: #fff;
  }
.orange{
      background-color: #FFAB52;
      color: #fff;
  }
.tag-read{
  cursor: pointer;
  vertical-align: bottom;
  margin-left: 3px;
}
.tag-read img {
  vertical-align: sub;
}
</style>