<template>
    <div>
        <div class="scanTList myVisitListWrap">
            <commonTable
                    :tableData="tableData"
                    :loading="loading"
                    @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange"
                    :paginationVisible="false"
                    :marginTop="'10px'"
            >
                <!--      :currentPage="currentPage"
                                    :total="total"           -->
                <template v-slot:table>
                    <el-table-column align="center" type="index" label="排名" />
                    <el-table-column prop="nickeName" align="center" label="微信昵称">
                        <template slot-scope="scope">
                            <span><img :src="scope.row.avatarUrl? scope.row.avatarUrl : defaultAvatar" alt="" class="avatorImg"></span>
                            <span>{{ scope.row.nickeName? scope.row.nickeName: '微信用户' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" align="center" label="销售额(元)">
                        <template slot-scope="scope">
                            <span>{{changeMoney(scope.row.price)}}</span>
                        </template>
                    </el-table-column>

                </template>
            </commonTable>
        </div>
    </div>
</template>

<script>
    import commonTable from "@/components/common/commonTable";
    import { fetchUserTopData } from "@/api/scanStatics";
    export default {
        name: "userTableTop",
        components: {
            commonTable
        },
        data() {
            return {
                defaultAvatar: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/hstx.png',
                tableData: [],
                pageSize: 1,
                currentPage: 1,
                loading: true
            }
        },
        mounted() {
            this.getUserData()
        },
        methods: {
            changeMoney(num) {
                var regexp = /(?:\.0*|(\.\d+?)0+)$/
                if (num > 1000000) {
                    num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
                    return num + 'w'
                } else {
                    num = (num / 100).toFixed(2)
                    num = num.replace(regexp, '$1')
                    return num
                }
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.queryPage();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.queryPage();
            },
            getUserData() {
                fetchUserTopData({
                    num: 5
                }).then(res => {
                    this.loading = false
                    console.log('getUserTop',res);
                    if (res.code==200) {
                        this.tableData = res.data
                    }
                }).catch(() => {
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>
    .scanTList{
        width: 96%;
        margin: auto;
        padding-bottom: 20px;
    }
    .avatorImg{
        width: 30px;
        height: 30px;
        border-radius: 30px;
        vertical-align: middle;
    }
</style>

