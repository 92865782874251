<template>
    <div class="contentBox clearfix">
        <div class="rowBox clearfix" style="background: #fff;margin-bottom: 15px">
            <div class="scanTimeBox borBtm">
                <div class="comTit">实时用户数</div>
                <div class="realTimeMenu">
                    <span :class="{ active: realDataTab === 1}" @click="switchTableData(1)">今日</span>
                    <span :class="{ active: realDataTab === 2}" @click="switchTableData(2)">昨日</span>
                    <span :class="{ active: realDataTab === 3}" @click="switchTableData(3)">近7日</span>
                    <span :class="{ active: realDataTab === 4}" @click="switchTableData(4)">近30日</span>
                </div>
                <div class="dataTableWrap">
                    <dataTable :data="tableData" :typeNums="realDataTab"></dataTable>
                </div>
            </div>
            <div class="scanTimeBox borBtm">
                <div>
                    <div class="comTit">扫码异常</div>
                    <!-- <span class="waringMore" @click="waringMore">更多>></span> -->
                </div>
                <WaringTable></WaringTable>
            </div>
            <div class="scanTimeBox">
                <div class="comTit">扫码类别</div>
                <div class="HomeTabMenu">
                    <div class="menuTab" :class="{'active': menuTab === 1}" @click="switchMenu(1)">产品码</div>
                    <!-- <div class="menuTab" :class="{'active': menuTab === 2}" @click="switchMenu(2)">箱标码</div> -->
                    <!-- <div class="menuTab" :class="{'active': menuTab === 6}" @click="switchMenu(6)">盒标码</div> -->
                    <div class="menuTab" :class="{'active': menuTab === 3}" @click="switchMenu(3)">物料码</div>
                    <!-- <div class="menuTab" :class="{'active': menuTab === 4}" @click="switchMenu(4)">名片码</div> -->
                    <div class="menuTab" :class="{'active': menuTab === 5}" @click="switchMenu(5)">证书码</div>
                    
                </div>
                <div class="dataTableWrap">
                    <scannerType :data="menuData" :typeNums="menuTab"></scannerType>
                </div>
            </div>
        </div>
        <!--购买统计弹窗-->
        <WaringDialog ref="waringDialog"></WaringDialog>
    </div>
</template>

<script>
    import DataTable from '../detailDialog/DataTable'
    import scannerType from '../detailDialog/ScannerType'
    import WaringTable from '../detailDialog/WaringTable'
    import WaringDialog from '../detailDialog/WaringDialog'
    export default {
        name: "ScanAnalysis",
        components: {
            DataTable,
            scannerType,
            WaringTable,
            WaringDialog
        },
        data() {
            return {
                realDataTab: 1,
                menuTab: 1,
                tableData: [],
                menuData: []
            }
        },
        methods: {
            switchTableData(tab) {
                this.realDataTab = tab
            },
            switchMenu(tab) {
                this.menuTab = tab
            },
            waringMore() {
                this.$refs.waringDialog.openDialog()
            }
        }
    }
</script>

<style scoped>
    .contentBox {
        width: 100%;
        height: auto;
        padding: 1px;
    }

    .padLeft15 {
        padding-left: 15px;
    }

    .rowBox {
        width: 100%;
        height: auto;
        padding: 1px;
    }
    .scanTimeBox{
       width: 100%;
        padding: 5px;
    }
    .borBtm{
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
    }
    .comTit {
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        color: #31353D;
        line-height: 30px;
        padding: 14px 0 14px 16px;
        position: relative;
        z-index: 1;
    }

    .comTit::after {
        content: "";
        display: block;
        width: 75%;
        height: 11px;
        background: rgba(226, 234, 255, 0.5);
        position: absolute;
        z-index: -1;
        right: -5px;
        bottom: 14px;
    }

    .realTimeMenu {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .realTimeMenu > span {
        font-size: 16px;
        font-weight: 500;
        color: #909EB3;
        line-height: 35px;
        width: 120px;
        text-align: center;
        height: 35px;
        border-radius: 30px;
        cursor: pointer;
    }
    .realTimeMenu .active {
        background: #287EFF;
        color: #fff;
    }
    .dataTableWrap {
        margin-top: 15px;
        width: 100%;
        height: auto;
        background: #fff;
    }
    .HomeTabMenu{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .HomeTabMenu .menuTab{
        font-size: 18px;
        font-weight: 500;
        color: #909EB3;
        line-height: 21px;
        position: relative;
        cursor: pointer;
    }
    .HomeTabMenu .menuTab.active {
        color: #4B596D;
    }
    .HomeTabMenu .menuTab.active::after{
        content: "";
        width: 70%;
        height: 3px;
        background: #4767F0;
        border-radius: 2px;
        display: block;
        position: absolute;
        bottom: -6px;
        left: 15%;
    }
    .waringMore{
        float: right;
        color: #4767F0;
        margin-right: 30px;
        padding-top: 20px;
        font-size: 14px;   
        cursor: pointer;
        }
</style>
