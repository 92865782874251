<template>
  <div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="prodDialogVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="80%"
    >

      <commonTable
          :tableData="tableData"
          :loading="loading"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :currentPage="pageNum"
          :total="total"
      >
        <template v-slot:table>
          
          <template v-if="logType === 1">
            <!-- <el-table-column prop="index" align="center" label="排名">
              <template slot-scope="scope">
                <template v-if="scope.$index === 0">
                  <img class="jiangImg" src="../../../../assets/images/jin.png" />
                </template>
                <template v-else-if="scope.$index === 1">
                  <img class="jiangImg" src="../../../../assets/images/yin.png" />
                </template>
                <template v-else-if="scope.$index === 2">
                  <img class="jiangImg"  src="../../../../assets/images/tong.png" />
                </template>
                <template v-else>
                {{ scope.$index + 1 }}
                </template>
              </template>
            </el-table-column> -->
						<el-table-column prop="index" align="center" label="排名">
						  <template slot-scope="scope">
						    <template>
						    {{ scope.$index + 6 }}
						    </template>
						  </template>
						</el-table-column>
            <el-table-column prop="name" align="center" label="产品名称">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column prop="avatarUrl" align="center" label="销量(元)">
              <template slot-scope="scope">
                <span>{{ changeMoney(scope.row.price) }}</span>
              </template>
            </el-table-column>
          </template>
          <template v-else-if="logType === 2">
            <el-table-column prop="index" align="center" label="排名">
              <!-- <template slot-scope="scope">
                <template v-if="scope.$index === 0">
                  <img class="jiangImg" src="../../../../assets/images/jin.png" />
                </template>
                <template v-else-if="scope.$index === 1">
                  <img class="jiangImg" src="../../../../assets/images/yin.png" />
                </template>
                <template v-else-if="scope.$index === 2">
                  <img class="jiangImg"  src="../../../../assets/images/tong.png" />
                </template>
                <template v-else>
                {{ scope.$index + 1 }}
                </template>
              </template> -->
							  <template slot-scope="scope">
							    <template>
							    {{ scope.$index + 6 }}
							    </template>
							  </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="用户">
              <template slot-scope="scope">
                <img :src="scope.row.avatarUrl" class="avatar" /><span>{{ scope.row.nickeName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="地域信息">
              <template slot-scope="scope">
                {{ scope.row.address }}
              </template>
            </el-table-column>
            <el-table-column prop="avatarUrl" align="center" label="销售额(元)">
              <template slot-scope="scope">
                <span>{{ changeMoney(scope.row.price) }}</span>
              </template>
            </el-table-column>
          </template>
          
        </template>
      </commonTable>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { fetchSaleList, fetchUserBuyList } from "@/api/scanStatics"
export default {
  name: "prodDialog",
  components: {
    commonTable
  },
  data() {
    return {
      prodDialogVisible: false,
      tableData: [],
      loading: false,
      pageSize: 10,
      pageNum: 1,
      total: 1,
      logType: 1,
      dialogTitle: '产品销售额详情',
    }
  },
  methods: {
    changeMoney(num) {
      var regexp = /(?:\.0*|(\.\d+?)0+)$/
      if (num > 1000000) {
        num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
        return num + 'w'
      } else {
        num = (num / 100).toFixed(2)
        num = num.replace(regexp, '$1')
        return num
      }
    },
    openDialog(type, data) {
      this.logType=type
      this.pageNum = 1
      this.pageSize = 10,
      this.getTableData()
      let title = ''
      if(type === 1) {
        title = '产品销售额'
      }else if( type === 2) {
        title = '用户购买量'
      }
      this.dialogTitle = title
      // this.prodDialogVisible = true
    },
    closeDialog() {
      this.prodDialogVisible = false
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getTableData()
    },
    handleCurrentChange(num){
      this.pageNum = num
      this.getTableData()
    },
    getTableData() {
      console.log('get')
      let params={
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        token: localStorage.getItem('token'),
      }
      let v=this
      if(this.logType === 1) {
        fetchSaleList(params).then((res) => {
          console.log("产品详情", res);
          if (res.code == 200) {
            v.tableData = res.data.list
            v.total = res.data.total
            v.pageNum = res.data.pageNum
          }
          if(!this.prodDialogVisible) {
            this.prodDialogVisible = true
          }
        })
      }else if(this.logType === 2) {
        fetchUserBuyList(params).then((res) => {
          if (res.code == 200) {
            v.tableData = res.data.list
            v.total = res.data.total
            v.pageNum = res.data.pageNum
          }
          if(!this.prodDialogVisible) {
            this.prodDialogVisible = true
          }
        })
      }

    }
  },
  filters: {
    getSource(val) {
      let arr = ['名片', '宣传物料','产品','箱标', '证书']
      //0名片 1宣传物料 2产品 3箱标 4证书
      let str = arr[val]? arr[val] : '--'
      return  str
    }
  },
  mounted() {


  }
}
</script>

<style scoped>
.avatar{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.timeShowTit {
  font-size: 12px;
  background-color: #62baff;
  color: #fff;
  border-radius: 4px;
  height: 25px;
  padding: 3px 8px;
  line-height: 25px;
  margin: auto;
}
.jiangImg{
  height: 30px;
  width: 26px;
}
</style>