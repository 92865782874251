<template>
    <div class="contentBox clearfix">
        <!--<div style="background: #fff;padding: 15px 10px">
            <span>数据概况</span>
            <el-divider></el-divider>
            <dataOverview></dataOverview>
        </div>-->
        <el-tabs class="tabs" v-model="activeNums">
            <el-tab-pane label="数据概览" name="first">
                <dataOverview :type="activeNums == 'first' && 1" v-if="activeNums == 'first'" ></dataOverview>
            </el-tab-pane>

            <el-tab-pane label="扫码分析" name="second">
                <scanAnalysis :type="activeNums == 'second' && 2" v-if="activeNums == 'second'" />
            </el-tab-pane>

            <el-tab-pane label="产品分析" name="third">
                <productAnalysis :type="activeNums == 'third' && 3" v-if="activeNums == 'third'" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import dataOverview from "./scanIndex/DataOverview"
    import scanAnalysis from "./scanIndex/ScanAnalysis"
    import productAnalysis from "./scanIndex/productAnalysis"
    export default {
        name: "scanStatistics",
        components: {
            dataOverview,
            scanAnalysis,
            productAnalysis
        },
        data() {
            return {
                activeNums: 'first',
            }
        },
        created() {
        },
        methods: {},
        mounted() {
        },
        beforeDestroy() {
        }
    }
</script>

<style scoped>
    .contentBox {
        width: 100%;
        height: auto;
    }
    /deep/.el-tabs--top .el-tabs__header {
        margin-bottom: 0px !important;
    }
</style>

