<template>
    <div>
        <div class="pageList">
            <div class="listData">
                <div class="grid-text name">扫码人次</div>
                <div class="grid-text quantity">{{pageList.scanNum}}</div>
            </div>
            <div class="listData">
                <div class="grid-text name">扫码人数</div>
                <div class="grid-text quantity">{{pageList.scanPeopleNum}}</div>
            </div>
            <div class="listData">
                <div class="grid-text name">扫码市区</div>
                <div class="grid-text quantity">{{pageList.scanCityNum}}</div>
            </div>
            <div class="listData">
                <div class="grid-text name">今日扫码人次</div>
                <div class="grid-text quantity">{{pageList.scanTodayNum}}</div>
            </div>
            <div class="listData">
                <div class="grid-text name">今日扫码人数</div>
                <div class="grid-text quantity">{{pageList.scanTodayPeopleNum}}</div>
            </div>
            <div class="listData">
                <div class="grid-text name">今日扫码市区</div>
                <div class="grid-text quantity">{{pageList.scanTodayCityNum}}</div>
            </div>
        </div>
        <div class="parkWord">
            排行榜TOP5
        </div>
        <div class="scanTList myVisitListWrap">
            <commonTable
                    :tableData="tableData"
                    :loading="loading"
                    @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange"
                    :paginationVisible="false"
                    :marginTop="'10px'"
            >
<!--      :currentPage="currentPage"
                    :total="total"           -->
                <template v-slot:table>
                    <el-table-column align="center" type="index" label="排名" />
                    <el-table-column
                            prop="name"
                            align="center"
                            label="名称"
                            show-overflow-tooltip
                    />
                    <el-table-column prop="visitTotalNum" align="center" label="总访问次数">
                        <template slot-scope="scope">
                          <span style="color: #784BFF; text-decoration: underline; cursor:pointer;" @click="showDeatil(1, scope.row)">{{ scope.row.visitTotalNum }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="visitPeopleNum" align="center" label="总访问人数">
                        <template slot-scope="scope">
                          <span style="color: #fd634e; text-decoration: underline; cursor:pointer;" @click="showDeatil(2, scope.row)">{{ scope.row.visitPeopleNum }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="guestsNum" align="center" label="总访问区域">
                        <template slot-scope="scope">
                            <span @click="showDeatil(3, scope.row)" style="color: #558FF2; text-decoration: underline; cursor:pointer;">{{ scope.row.visitAreaNum }}</span>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
        <AllScanInfoDialog ref="allScanInfoDialog"></AllScanInfoDialog>
        <AllScanPeopleInfoDialog ref="allScanPeopleInfoDialog"></AllScanPeopleInfoDialog>
        <AllScanlScanAreaInfoDialog ref="allScanlScanAreaInfoDialog"></AllScanlScanAreaInfoDialog>
    </div>
</template>

<script>
    import { fetchScanInfoData,fetchScanInfoTopData } from "@/api/scanStatics";
    import commonTable from "@/components/common/commonTable";
    import AllScanInfoDialog from './allScanInfoDialog'
    import AllScanPeopleInfoDialog from './allScanPeopleInfoDialog'
    import AllScanlScanAreaInfoDialog from './allScanlScanAreaInfoDialog'

    export default {
        name: 'ScannerType',
        data() {
            return {
                infoData: {},
                isLoadMore: false,
                loadStatus: 'loading',
                detailList: [],
                realHeight: 0,
                dataList: [],
                pageList: {
                    scanCityNum: 0,
                    scanNum: 0,
                    scanPeopleNum: 0,
                    scanTodayCityNum: 0,
                    scanTodayNum: 0,
                    scanTodayPeopleNum: 0,
                },

                currentPage: 1, //当前页
                pageSize: 10, //显示条数
                loading: false, //表格加载
                total: 0, //总条数
                tableData: []
            }
        },
        components: {
            commonTable,
            AllScanInfoDialog,
            AllScanPeopleInfoDialog,
            AllScanlScanAreaInfoDialog
        },
        props: {
            typeNums: {
                type: Number,
                default: 1
            },
        },
        watch: {
            isShow: { //深度监听，可监听到对象、数组的变化
                handler(newV, oldV) {
                    // do something, 可使用this
                    console.log(newV, oldV)
                    // this.typeNums = newV
                    this.fetchProductData()
                },
                deep: true
            }
        },
        onLoad() {},
        computed: {
            isShow() {
                return this.typeNums
            }
        },
        created() {

        },
        methods: {
            handleSizeChange(val) {
                this.pageSize = val;
                this.queryPage();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.queryPage();
            },
            getData(id) {
                console.log(id)
                fetchScanInfoData({
                    myCardId: id,
                    source: id
                }).then(response => {
                    if (response) {
                        console.log('fetchScanInfoData',response.data)
                        this.pageList = response.data;
                        var dataTop = {
                            source: id,
                            num: 5
                        }
                        fetchScanInfoTopData(dataTop).then(res => {
                            if (res) {
                                this.tableData = res.data
                                // res.map((item, index) => {
                                // 	this.$set(this.detailList, [index], {
                                // 		name: (item.name ? item.name : '---') + '  ' + (
                                // 			item.serialNumber ? item.serialNumber : ''),

                                // 	})
                                // })
                                console.log('tableData',this.tableData)
                            } else {

                            }
                        }).catch(() => {})
                    } else {

                    }
                }).catch(() => {})
            },
            fetchProductData() {
                if (this.typeNums == 1) {
                    this.getData(2)
                } else if (this.typeNums == 2) {
                    this.getData(3)
                } else if (this.typeNums == 3) {
                    this.getData(1)
                } else if (this.typeNums == 4) {
                    this.getData(0)
                } else if (this.typeNums == 5) {
                    this.getData(4)
                }else if (this.typeNums == 6) {
                    this.getData(5)
                }
            },
            showDeatil(type, data) {
                console.log(type)
                console.log(this.typeNums, '----区分类型')
                console.log(data)
                if(type === 1){ // 访问次数-详情
                  this.$refs.allScanInfoDialog.openDialog(this.typeNums, data)
                }else if( type === 2) { // 访问人数
                  this.$refs.allScanPeopleInfoDialog.openDialog(this.typeNums, data)
                }else if( type ===3 ){ // 访问区域
                 this.$refs.allScanlScanAreaInfoDialog.openDialog(this.typeNums, data)
                }
            }
        },
        mounted() {
            this.fetchProductData()
        }
    }
</script>

<style scoped lang="scss">
    .pageList {
        width: 96%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-content: space-around;
        flex-wrap: wrap;
    }

    .pageList>.listData {
        width: -webkit-calc(15% - 10px);
        display: inline-block;
        margin-top: 10px;
        margin-left: 10px;
        padding: 8px;
        padding-left: 20px;
        box-sizing: border-box;
    }

    .listData:nth-child(3n+1) {
        background: url(../../../../assets/images/scan/d_1.png) top center no-repeat;
        background-size: 100% 100%;

        .quantity {
            color: #784BFF;
            font-size: 20px;
            font-weight: bold;
        }

        .name {
            color: #B7A0FF;
            font-size: 12px;
        }
    }

    .listData:nth-child(2),
    .listData:nth-child(5) {
        background: url(../../../../assets/images/scan/d_2.png) top center no-repeat;
        background-size: 100% 100%;
        .quantity {
            color: #FD8E4E;
            font-size: 20px;
            font-weight: bold;
        }

        .name {
            color: #FD8E4E;
            font-size: 12px;
        }
    }

    .listData:nth-child(3n) {
        background: url(../../../../assets/images/scan/d_3.png) top center no-repeat;
        background-size: 100% 100%;
        .quantity {
            color: #558FF2;
            font-size: 20px;
            font-weight: bold;
        }

        .name {
            color: #A0BFFF;
            font-size: 12px;
        }
    }

    .grid-text {
        padding: 5px !important;
    }

    // .pageList {
    // 	width: 100%;
    // 	padding: 0 16px;
    // }

    // .listData {
    // 	font-size: 12px;
    // 	margin: 5px;
    // 	padding: 0 5px;
    // 	border: 1px solid #e5e5e5;
    // 	width: 30%;
    // 	display: inline-block;
    // }

    .grid-text {
        font-size: 14px;
        color: #909399;
        padding: 10px 0 20px 0px;
        box-sizing: border-box;
    }

    .parkWord {
        width: 200px;
        height: 40px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #31353D;
        line-height: 40px;
        padding-left: 55px;
        box-sizing: border-box;
        position: relative;
        z-index: 9;
        margin-top: 10px;
    }

    .parkWord::after {
        content: '';
        width: 60px;
        height: 11px;
        background: rgba(217, 224, 243, 0.5);
        position: absolute;
        left: 55px;
        bottom: 5px;
        z-index: -1;
    }

    .my_collapse_title {
        height: 80px;
        vertical-align: middle;

        .coll_title_img {
            width: 45px;
            height: 50px;
            display: inline-block;
            text-align: center;
            line-height: 50px;
            font-size: 30px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #FFFFFF;
            text-shadow: 0px 1px 1px #9CB3C2;
            margin-top: 24px;
            margin-left: 28px;
            margin-right: 26px;
            vertical-align: middle;

            .parkNums {
                width: 18px;
                height: 18px;
                background: rgba(144, 158, 179, 0.06);
                border: 1px solid #C4D1E6;
                color: #A3B2C9;
                font-weight: 400;
                display: block;
                border-radius: 18px;
                line-height: 18px;
            }
        }

        .coll_tit {
            font-size: 30px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            width: 75%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 26px;
            vertical-align: middle;
        }
    }

    .scanTList {
        width: 94%;
        margin: 0 0 0 3.5%;
    }

    .chartsList {
        width: 100%;
        height: calc(100% - 300px);
        // position: fixed;
        left: 0;
        top: 500px;
        padding: 0 2%;
        box-sizing: border-box;
        overflow: hidden;
    }

    .my_collapse_content {
        width: 100%;
        margin: 0 auto 5px;
        padding: 0 0 10px;

        .coll_total {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding-bottom: 5px;
            width: 100%;
            margin: 0 auto;
            padding-bottom: 20px;

            .coll_total_block {
                width: 190px;
                height: 95px;
                border-radius: 16px;
                text-align: center;
                color: #127BFE;
                position: relative;

                &::after {
                    content: '';
                    width: 1px;
                    height: 60%;
                    background-color: #fff;
                    position: absolute;
                    right: 0;
                    top: 20%;
                }

                .coll_total_label {
                    font-size: 22px;
                    font-family: Alibaba PuHuiTi;
                    font-weight: 500;
                    color: #939EB1;
                }

                .coll_total_number {
                    font-size: 36px;
                    font-family: Alibaba PuHuiTi;
                    font-weight: bold;
                    padding: 6px 0;
                    color: #708BFF;
                }

            }
        }

        .conItems:first-child .coll_list_wrap {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        .coll_list_wrap {
            overflow: hidden;

            .coll_list_title {
                border-bottom: 3px solid #D6F2FD;

                font-size: 24px;
                font-family: Alibaba PuHuiTi;
                font-weight: 400;
                color: #127BFE;

                >text {
                    display: inline-block;
                    width: 33%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: center;
                    padding: 24px 0;
                }
            }

            .coll_list_item {
                padding: 20px 0;

                >text {
                    display: inline-block;
                    width: 33%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: center;
                    font-size: 20px;
                    font-family: Alibaba PuHuiTi;
                    font-weight: 400;
                    color: #333333;

                    .account_img {
                        width: 43px;
                        height: 43px;
                        border-radius: 50%;
                        vertical-align: middle;
                        margin-right: 6px;
                    }
                }
            }
        }
    }

    .myVisitListWrap /deep/ .uni-collapse-item {
        border-radius: 16px;
        margin-bottom: 20px;

    }

    .myVisitListWrap /deep/ .conItems {

        margin-bottom: 0px;
        position: relative;

        &:nth-child(1) .my_collapse_title .coll_title_img {
            background: url('../../../../assets/images/scan/park_1.png') top left no-repeat;
            background-size: 100% 100%;
        }

        &:nth-child(2) .my_collapse_title .coll_title_img {
            background: url('../../../../assets/images/scan/park_2.png') top left no-repeat;
            background-size: 100% 100%;
        }

        &:nth-child(3) .my_collapse_title .coll_title_img {
            background: url('../../../../assets/images/scan/park_3.png') top left no-repeat;
            background-size: 100% 100%;
        }
    }

    .conItems:nth-child(even) {
        background: #F7F8FB;
    }
</style>

