<template>
    <div>
        <div class="scanTList myVisitListWrap">
            <commonTable
                    :tableData="tableData"
                    :loading="loading"
                    @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange"
                    :paginationVisible="false"
                    :marginTop="'10px'"
            >
                <!--      :currentPage="currentPage"
                                    :total="total"           -->
                <template v-slot:table>
                    <el-table-column align="center" type="index" label="排名" />
                    <el-table-column prop="name" align="center" label="产品名称">
                        <template slot-scope="scope">
                            <span>{{ scope.row.name? scope.row.name: '---' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" align="center" label="销售额(元)">
                        <template slot-scope="scope">
                            <span>{{ changeMoney(scope.row.price) }}</span>
                        </template>
                    </el-table-column>

                </template>
            </commonTable>
        </div>
      
    </div>
</template>

<script>
    import commonTable from "@/components/common/commonTable";
    import { fetchGoodTopData } from "@/api/scanStatics";
    export default {
        name: "proTableTop",
        components: {
            commonTable,
        },
        data() {
            return {
                tableData: [],
                pageSize: 1,
                currentPage: 1,
                loading: true
            }
        },
        mounted() {
            this.getGoodsData()
        },
        methods: {
            changeMoney(num) {
                var regexp = /(?:\.0*|(\.\d+?)0+)$/
                if (num > 1000000) {
                    num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
                    return num + 'w'
                } else {
                    num = (num / 100).toFixed(2)
                    num = num.replace(regexp, '$1')
                    return num
                }
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.queryPage();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.queryPage();
            },
            getGoodsData() {
                fetchGoodTopData({
                   num: 5
                }).then(res => {
                    this.loading = false
                    console.log('getGoodsData',res);
                    if (res.code==200) {
                      if (res.data.length==0) {
                        this.tableData = []
                      } else {
                        this.tableData = res.data
                      }

                    }
                }).catch(() => {
                    this.loading = false
                })
            },
        }
    }
</script>

<style scoped>
.scanTList{
    width: 96%;
    margin: auto;
}
</style>
