<template>
	<div>
		<div class="scanTList myVisitListWrap" style="display: flex;justify-content: flex-start;">
			<div style="background-image: linear-gradient(to right , #F6F5F8, #FFFFFF);padding: 10px;width: 28%;height: 106px;line-height: 30px;margin-right: 30px;border-radius: 10px;"
				v-for="(item, index) in tableData" :key="index">
				<div class="title">
					<img v-if="item.warningCode == 1" src="../../../../assets/images/yd.png" alt="" width="30px"
						height="30px" style="margin-right: 10px;">
					<img v-if="item.warningCode == 2" src="../../../../assets/images/sm.png" alt="" width="30px"
						height="30px" style="margin-right: 10px;">
					<img v-if="item.warningCode == 3" src="../../../../assets/images/yz.png" alt="" width="30px"
						height="30px" style="margin-right: 10px;">
					<img v-if="item.warningCode == 4" src="../../../../assets/images/ch@2x.png" alt="" width="30px"
						height="30px" style="margin-right: 10px;">
					<div>{{ item.warningCode | setStatus }}</div>
				</div>
				<div class="name">{{item.title}}{{item.handle}}</div>
				<div class="date">{{item.createTime}}</div>
			</div>
			<div @click="waringMore" style="height: 106px;width: 106px;background-image: linear-gradient(to right, #F6F5F8 70%, #FFFFFF);display: flex;align-items: center;justify-content: center;position: absolute;right: 0;top: 0;flex-direction: column">
				<img src="../../../../assets/images/more.png" alt="" width="30px" height="30px">
				<div class="more">查看更多</div>
			</div>

			<!-- <commonTable
                    :tableData="tableData"
                    :loading="loading"
                    @handleSizeChange="handleSizeChange"
                    @handleCurrentChange="handleCurrentChange"
                    :paginationVisible="false"
                    :marginTop="'10px'"
            >
                <template v-slot:table>
                    <el-table-column prop="warningCode" align="center" label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.warningCode == 0">正常</span>
                            <span v-if="scope.row.warningCode == 1">异地扫码</span>
                            <span v-if="scope.row.warningCode == 2">扫码异常</span>
                            <span v-if="scope.row.warningCode == 3">验证异常</span>
                        </template>
                    </el-table-column>
                   <el-table-column prop="warningCode" align="center" label="内容">
                       <template slot-scope="scope">
                        <div v-if="scope.row.warningCode == 0">
                        <div>{{scope.row.warningInfo}}</div>
                        </div>
                        <div v-if="scope.row.warningCode == 1">
                            <div><span>{{scope.row.createTime}}{{scope.row.title}}</span><span>{{scope.row.handle}}</span><span>在{{scope.row.address}}被{{scope.row.nickeName}}访问</span></div>
                            <div v-if="scope.row.lastTime">上次扫码信息：{{scope.row.lastTime}}在{{scope.row.lastAddress}}扫码</div>
                        </div>
                        <div v-if="scope.row.warningCode == 2">
                            <div><span>{{scope.row.createTime}}{{scope.row.title}}</span><span @click="copyText(scope.row.handle)" style="color: #2196f3;word-break:break-all">{{scope.row.handle}}</span><span>第{{scope.row.num}}次访问</span></div>
                            <div>扫码次数上限为：{{scope.row.outValue}}次</div>
                        </div>
                        <div v-if="scope.row.warningCode == 3">
                            <div><span>{{scope.row.createTime}}{{scope.row.title}}</span><span @click="copyText(scope.row.handle)" style="color: #2196f3;word-break:break-all">{{scope.row.handle}}</span><span>第{{scope.row.num}}次验证</span></div>
                            <div>验证次数超阈值为：{{scope.row.verificationNum}}次</div>
                        </div>
                       </template>
                   </el-table-column>
                   <el-table-column prop="createTime" align="center" label="时间"></el-table-column>
                    

                </template>
            </commonTable> -->
		</div>
		<WaringDialog ref="waringDialog"></WaringDialog>
	</div>
</template>

<script>
	// import commonTable from "@/components/common/commonTable";
	import WaringDialog from './WaringDialog'
	import {
		fetchWaringData
	} from "@/api/scanStatics";
	export default {
		name: "waringTable",
		components: {
			// commonTable,
			WaringDialog
		},
		data() {
			return {
				tableData: [],
				pageSize: 1,
				currentPage: 1,
				loading: true
			}
		},
		mounted() {
			this.getTableData()
		},
		filters: {
			setStatus: function(type) {
				const statusList = {
					0: '正常',
					1: '异地扫码',
					2: '扫码异常',
				    3: '验证异常',
					4: '窜货提醒',
				}
				return statusList[type]
			}
		},
		methods: {
			waringMore() {
			    this.$refs.waringDialog.openDialog()
			},
			changeMoney(num) {
				var regexp = /(?:\.0*|(\.\d+?)0+)$/
				if (num > 1000000) {
					num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
					return num + 'w'
				} else {
					num = (num / 100).toFixed(2)
					num = num.replace(regexp, '$1')
					return num
				}
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.queryPage();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.queryPage();
			},
			getTableData() {
				fetchWaringData({
					companyId: JSON.parse(localStorage.getItem('info')).companyId
				}).then(res => {
					this.loading = false
					console.log('fetchWaringData', res);
					if (res.code == 200) {
						this.tableData = res.data.list.slice(0, 3)
					}
				}).catch(() => {
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped>
	.scanTList {
		width: 96%;
		margin: auto;
		padding-bottom: 20px;
		position: relative;
	}

	.avatorImg {
		width: 30px;
		height: 30px;
		border-radius: 30px;
		vertical-align: middle;
	}

	.title {
		font-size: 18px;
		font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
		font-weight: 400;
		color: #1F1D1D;
		display: flex;
		align-items: center;
	}

	.name {
		font-size: 14px;
		font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
		font-weight: 400;
		color: #1F1D1D;
		padding-left: 40px;
	}

	.date {
		font-size: 14px;
		font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
		font-weight: 400;
		color: #AAB3CB;
		padding-left: 40px;
	}
	
	.more {
		font-size: 14px;
		font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
		font-weight: 400;
		color: #A8B3CE;
		line-height: 20px;
	}
</style>
