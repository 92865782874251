<template>
  <div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="80%"
    >

      <commonTable
          :tableData="tableData"
          :loading="loading"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :currentPage="pageNum"
          :total="total"
      >
        <template v-slot:table>
            <el-table-column prop="area" align="center" label="扫码市区">
              <template slot-scope="scope">
                <span>{{scope.row.area? scope.row.area : '---'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="num" align="center" label="扫码数量">
            </el-table-column>
          </template>
          
      </commonTable>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { fetchScanAreaData } from "@/api/scanStatics"
export default {
  name: "prodDialog",
  components: {
    commonTable
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      loading: false,
      pageSize: 10,
      pageNum: 1,
      total: 1,
      dialogTitle: '扫码地域分布',
      typeNums: null, // 区分类型
      paramsId: null,
    }
  },
  methods: {
    changeMoney(num) {
      var regexp = /(?:\.0*|(\.\d+?)0+)$/
      if (num > 1000000) {
        num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
        return num + 'w'
      } else {
        num = (num / 100).toFixed(2)
        num = num.replace(regexp, '$1')
        return num
      }
    },
    openDialog(typeNums, data) {
      this.pageNum = 1
      this.pageSize = 10,
      this.dialogVisible = true
      this.getTableData()
    },
    closeDialog() {
      this.dialogVisible = false
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getTableData()
    },
    handleCurrentChange(num){
      this.pageNum = num
      this.getTableData()
    },
    getTableData() {
      let params={
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      fetchScanAreaData(params).then(res => {
        if(!this.dialogVisible) {
          this.dialogVisible = true
        }
        this.tableData = res.data.list
        this.total = res.data.total
        this.pageNum = res.data.pageNum
      })
    },
    // 获取浏览时长class颜色
    getTimeColor(time){
        let colorArr = ['grey','blue','orange']
        if(time && time < 60){
            return colorArr[0]
        }else if(time && time >= 60 && time < 600 ) {
            return colorArr[1]
        }else if(time >= 600) {
            return colorArr[2]
        }
    },
    getTimeText(time) {
      if(time && time < 600){
        return time + 's'
      }else if(time >= 600) {
        return '600+'
      }else {
        return '-'
      }
    },
  },
  filters: {
  },
  mounted() {


  }
}
</script>

<style scoped>
.avatar{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.timeShowTit {
  font-size: 12px;
  background-color: #62baff;
  color: #fff;
  border-radius: 4px;
  height: 25px;
  padding: 3px 8px;
  line-height: 25px;
  margin: auto;
}
.jiangImg{
  height: 30px;
  width: 26px;
}
.time_span{
  padding: 2px 14px;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
}
.grey{
      background-color: #EAEFFF;
      color: #4A7AF0;
  }
.blue{
      background-color: #8EABFF;
      color: #fff;
  }
.orange{
      background-color: #FFAB52;
      color: #fff;
  }
</style>