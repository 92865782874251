<template>
    <div class="contentBox clearfix">
        <div class="rowBox clearfix" style="background: #fff;padding-bottom: 15px">
            <div class="scanTimeBox">
                <div class="ibox">
                    <div class="titForTips">
                        实时数据统计
                    </div>
                    <div id="review_box">
                        <div id="news">
                            <div class="topsBox middle_font">
                                <div class="topItems top_type" style="width: 8%">类型</div>
                                <div class="topItems top_add" style="width: 17%">地区</div>
                                <div class="topItems top_pro" style="width: 18%">产品(姓名)</div>
                                <div class="topItems top_iden" style="width: 15%">标识</div>
                                <div class="topItems top_time" style="width: 18%">时间</div>
                                <div class="topItems " style="width: 24%;">客户</div>
                            </div>
                            <ul id="news_ul">
                                <li class="iden_li" v-for="(item,index) in scanList" :key="index">
                                    <div class="liItems top_type titActive" style="width: 8%" :title="item.type">{{item.type? item.type : '-'}}</div>
                                    <div class="liItems" style="width: 17%;padding-left: 5px;" :title="item.address">
                                        {{item.address? item.address: '-'}}
                                    </div>
                                    <div class="liItems top_pro titActive" style="width: 18%" :title="item.title">{{item.title ? item.title : '-'}}</div>
                                    <div class="liItems top_iden titActive" style="width: 18%;padding-right: 2%" :title="item.handle">
                                        {{item.handle ? item.handle : '-'}}
                                    </div>
                                    <div class="liItems top_time titActive" style="width: 16%;" :title="item.createTime">{{item.createTime ? item.createTime : '-'}}
                                    </div>
                                    <div class="liItems top_time titActive" style="width: 20%;padding-left: 2%" :title="item.nickeName"><span
                                            class="nickSpanBlock">
                                        <img :src="item.avatarUrl ? item.avatarUrl : defaultAvatar" alt="" class="avatarImg">
                                        {{item.nickeName? item.nickeName: '微信用户'}}</span>
                                        <span class="timeShow" :class="getTimeColor(item.seeTime)" v-if="item.seeTime">
                                            {{ getTimeText(item.seeTime) ? getTimeText(item.seeTime) : '-' }}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="linesBlock"></div>
                    </div>
                </div>
            </div>
            <div class="mapShow">
                <div class="ibox">
                    <div class="ibox-content" style="height: auto;padding: 15px 5px 10px 5px;border-color: #fff">
                        <div ref="myEchart" id="echartsmap" style="width: 100%;height: 275px;margin-top: 30px;z-index: 19"></div>
                    </div>
                </div>
            </div>
            <div class="pieShowBox">
                <el-button type="primary" class="detailTitle" plain @click="detailsClick()">详情</el-button>
                <div class="pieBox" id="pieBox"></div>
                <span class="pieMore" plain @click="handleRegionMore">更多>></span>
            </div>
        </div>
        <div class="scansBox clearfix">
            <div class="scanItem scanItem1" :class="{'newsItems':companyMsg.isHaveNfc}">
                <div class="dataTipsBox">
                    <img src="@/assets/images/scan/icon_smtj2x.png" alt=""
                         class="tipBackImg">
                    <div class="tipsTitles">扫码统计</div>
                </div>
                <div class="codeItem" @click="handleScanDialog(1, scanData,'人次', false)">
                    <div class="codeNumber yell_Color" id="num5">{{scanData.allNum}}</div>
                    <div class="codeTitle">累计人次</div>
                </div>
                <div class="forLines"></div>
                <div class="codeItem" @click="handleScanDialog(1, scanData,'人次', true)">
                    <div class="codeNumber yell_Color" id="num6">{{scanData.todayNum}}</div>
                    <div class="codeTitle">今日扫码累计</div>
                </div>
                <div class="codeItem" @click="handleScanDialog(1, scanData,'市区', false)">
                    <div class="codeNumber yell_Color" id="num7">{{scanData.cityNum}}</div>
                    <div class="codeTitle">扫码市区</div>
                </div>
                <div class="forLines"></div>
                <div class="codeItem" @click="handleScanDialog(1, scanData,'市区', true)">
                    <div class="codeNumber yell_Color" id="num8">{{scanData.todayCityNum}}</div>
                    <div class="codeTitle">今日扫码市区</div>
                </div>
            </div>
            <!--   <div class="scanItem">
                   <div class="dataTipsBox">
                       <img src="@/assets/images/scan/icon_bug2x.png" alt=""
                            class="tipBackImg">
                       <div class="tipsTitles">NFC统计</div>
                   </div>
                   <div class="codeItem">
                       <div class="codeNumber nfc_color" id="num13">0</div>
                       <div class="codeTitle">扫码购买</div>
                   </div>
                   <div class="forLines"></div>
                   <div class="codeItem">
                       <div class="codeNumber nfc_color" id="num14">0</div>
                       <div class="codeTitle">今日扫码购买</div>
                   </div>

                   <div class="codeItem">
                       <div class="codeNumber nfc_color" id="num15">0</div>
                       <div class="codeTitle">购买市区</div>
                   </div>
                   <div class="forLines"></div>
                   <div class="codeItem">
                       <div class="codeNumber nfc_color" id="num16">0</div>
                       <div class="codeTitle">今日购买市区</div>
                   </div>
               </div>-->
            <div class="scanItem scanItem2" :class="{'newsItems':companyMsg.isHaveNfc}" :style="companyMsg.isHaveNfc?'margin: 0 1.33%':'margin: 0 2%'">
                <div class="dataTipsBox">
                    <img src="@/assets/images/scan/icon_yztj2x.png" alt=""
                         class="tipBackImg">
                    <div class="tipsTitles">验证统计</div>
                </div>
                <div class="codeItem" @click="handleScanDialog(2, scanData, '人次', false)">
                    <div class="codeNumber gre_color" id="num9">{{checkData.allNum}}</div>
                    <div class="codeTitle">累计人次</div>
                </div>
                <div class="forLines"></div>
                <div class="codeItem" @click="handleScanDialog(2, scanData, '人次', true)">
                    <div class="codeNumber gre_color" id="num10">{{checkData.todayNum}}</div>
                    <div class="codeTitle">今日累计人次</div>
                </div>
                <div class="codeItem" @click="handleScanDialog(2, scanData, '市区', false)">
                    <div class="codeNumber gre_color" id="num11">{{checkData.cityNum}}</div>
                    <div class="codeTitle">验证市区</div>
                </div>
                <div class="forLines"></div>
                <div class="codeItem" @click="handleScanDialog(2, scanData, '市区', true)">
                    <div class="codeNumber gre_color" id="num12">{{checkData.todayCityNum}}</div>
                    <div class="codeTitle">今日验证市区</div>
                </div>
            </div>
            <div class="scanItem scanItem3" :class="{'newsItems':companyMsg.isHaveNfc}" :style="companyMsg.isHaveNfc?'margin: 0 1.33% 0 0':''">
                <div class="dataTipsBox">
                    <img src="@/assets/images/scan/icon_bug2x.png" alt=""
                         class="tipBackImg">
                    <div class="tipsTitles">购买统计</div>
                </div>
                <!--  @click="handleBuyDialog(false)" -->
                <div class="codeItem" @click="handleScanDialog(3, scanData, '购买', false)"> 
                    <div class="codeNumber showActives" id="num1">{{scanBuyData.allNum}}</div>
                    <div class="codeTitle">累计购买</div>
                </div>
                <div class="forLines"></div>
                 <!--   @click="handleBuyDialog(true)" -->
                <div class="codeItem" @click="handleScanDialog(3, scanData, '购买', true)" >
                    <div class="codeNumber showActives" id="num2">{{scanBuyData.todayNum}}</div>
                    <div class="codeTitle">今日累计购买</div>
                </div>
                <!-- @click="handleScanDialog(3, scanData, '市区', false)" -->
                <div class="codeItem" @click="handleBuyCityDialog(false)"> 
                    <div class="codeNumber showActives" id="num3">{{scanBuyData.cityNum}}</div>
                    <div class="codeTitle">购买市区</div>
                </div>
                <div class="forLines"></div>
                <!-- @click="handleScanDialog(3, scanData, '市区', true)" -->
                <div class="codeItem" @click="handleBuyCityDialog(true)"> 
                    <div class="codeNumber showActives" id="num4">{{scanBuyData.todayCityNum}}</div>
                    <div class="codeTitle">今日购买市区</div>
                </div>
            </div>
            <div class="scanItem scanItem4" v-if="companyMsg.isHaveNfc" :class="{'newsItems':companyMsg.isHaveNfc}">
                <div class="dataTipsBox">
                    <img src="@/assets/images/scan/icon_smtj2x.png" alt=""
                         class="tipBackImg">
                    <div class="tipsTitles">NFC统计</div>
                </div>
                <div class="codeItem" @click="handleScanDialog(4, scanData, '人次', false)">
                    <div class="codeNumber yell_Color" id="num5">{{scanNfcData.allNum}}</div>
                    <div class="codeTitle">累计人次</div>
                </div>
                <div class="forLines"></div>
                <div class="codeItem" @click="handleScanDialog(4, scanData, '人次', true)">
                    <div class="codeNumber yell_Color" id="num6">{{scanNfcData.todayNum}}</div>
                    <div class="codeTitle">今日扫码累计</div>
                </div>
                <div class="codeItem" @click="handleScanDialog(4, scanData, '市区', false)">
                    <div class="codeNumber yell_Color" id="num7">{{scanNfcData.cityNum}}</div>
                    <div class="codeTitle">累计市区</div>
                </div>
                <div class="forLines"></div>
                <div class="codeItem" @click="handleScanDialog(4, scanData, '市区', true)">
                    <div class="codeNumber yell_Color" id="num8">{{scanNfcData.todayCityNum}}</div>
                    <div class="codeTitle">今日扫码市区</div>
                </div>
            </div>
        </div>
        <div class="echartBox">
            <div class="echartWrap">
                <div class="lengeBox">
                    <div class="linesForBox">
                        <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/icon_sm2x.png" alt="" class="linesImg">
                        <div class="scanShows">
                            <div class="newsNumss" id="sevenScanNums">{{scanCount7Day}}</div>
                            <div class="newsTitle">近7天扫码量</div>
                        </div>
                    </div>
                    <div class="linesForBox">
                        <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/icon_yz2x.png" alt="" class="linesImg">
                        <div class="scanShows">
                            <div class="newsNumss" style="color: #4C95F8" id="sevenCheckNums">{{verificationCount7Day}}</div>
                            <div class="newsTitle">近7天验证量</div>
                        </div>
                    </div>
                </div>
                <div id="myChart1" class="linesBox">
                </div>
            </div>
            <div class="echartWrap">
                <div class="lengeBox">
                    <div class="linesForBox">
                        <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/icon_sm2x.png" alt="" class="linesImg">
                        <div class="scanShows">
                            <div class="newsNumss" id="sevenScanNums">{{scanCount12Month}}</div>
                            <div class="newsTitle">近一年扫码量</div>
                        </div>
                    </div>
                    <div class="linesForBox">
                        <img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/icon_yz2x.png" alt="" class="linesImg">
                        <div class="scanShows">
                            <div class="newsNumss" style="color: #4C95F8" id="sevenCheckNums">{{verificationCount12Month}}</div>
                            <div class="newsTitle">近一年验证量</div>
                        </div>
                    </div>
                </div>
                <div id="myChart2" class="linesBox">
                </div>
            </div>
        </div>
        <detailDialog ref="detailDialog"></detailDialog>
        <!--扫码统计弹窗-->
        <scanDialog ref="scanDialog"></scanDialog>
        <!--扫码地域分部弹窗-->
        <regionMoreDialog ref="regionMoreDialog"></regionMoreDialog>
        <!--购买统计弹窗-->
        <BuyDialog ref="buyDialog"></BuyDialog>
        <!--购买统计市区弹窗-->
        <BuyCityDialog ref="buyCityDialog"></BuyCityDialog>
    </div>
</template>

<script>
    import echarts from "echarts";
    import 'echarts/map/js/china.js' // 引入中国地图数据
    import {getTimeScan, scanStaticTotal, fetchLineChartData, getScanAreaData, getTimeMapData} from "@/api/scanStatics";
    import { formatTimes, add0 } from "@/utils/utils"
    import detailDialog from "../detailDialog/index"
    import ScanDialog from "../detailDialog/ScanDialog"
    import regionMoreDialog from '../detailDialog/regionMoreDialog'
    import BuyDialog from "../detailDialog/BuyDialog"
    import BuyCityDialog from "../detailDialog/BuyCityDialog"
    import { fetchCompany } from '@/api/companyManage';
    export default {
        name: "DataOverview",
        components: {
            detailDialog,
            ScanDialog,
            regionMoreDialog,
            BuyDialog,
            BuyCityDialog
        },
        data() {
            return {
                activeNums: 'first',
                companyMsg: {},
                scanList: [],
                scanData: {},
                checkData: {},
                scanBuyData: {},
                scanNfcData: {},
                timer: null,
                tplTimes: null,
                hourIndex: 0,
                dynamicPro : '',
                scanCount12Month: 0,
                verificationCount12Month: 0,
                scanCount7Day: 0,
                verificationCount7Day: 0,
                viewWidth: 0,
                defaultAvatar: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/hstx.png'
            }
        },
        props: {
            type: {
                type: Number,
                default: 1,
            },
        },
        created() {
            this.viewWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        },
        methods: {
            // 购买统计弹窗
            handleBuyDialog(isToday) {
              this.$refs.buyDialog.openDialog(isToday)
            },
            handleBuyCityDialog(isToday) {
              this.$refs.buyCityDialog.openDialog(isToday)
            },
            // 获取浏览时长class颜色
            getTimeColor(time){
                let colorArr = ['grey','blue','orange']
                if(time && time < 60){
                    return colorArr[0]
                }else if(time && time >= 60 && time < 600 ) {
                    return colorArr[1]
                }else if(time >= 600) {
                    return colorArr[2]
                }
            },
            getTimeText(time) {
              if(time && time < 600){
                return time + 's'
              }else if(time && time >= 600) {
                return '600+'
              }else {
                return '-'
              }
            },
            //获取公司信息
            getCompany(){
                let params ={
                    myCardId: JSON.parse(localStorage.getItem('info')).cardId
                }

                fetchCompany(params)
                    .then(res=>{
                        this.companyMsg = res.data
                        console.log('companyMsg',this.companyMsg)
                    })
            },
            detailsClick() {
                this.$refs.detailDialog.showDialog()
            },
            handleRegionMore() {
                this.$refs.regionMoreDialog.openDialog()
            },
            fetchTimeData() {
                let self = this
                var nId = sessionStorage.getItem('logId')
                var data = {
                    num: 5
                }
                if (!nId) {

                } else {
                    if(self.scanList.length == 0) {
                        sessionStorage.removeItem('logId')
                    } else {
                        data.logId = nId
                    }
                }
                getTimeScan(data).then((res) => {
                    console.log("res", res);
                    if (res.code == 200) {
                        if (res.data.length > 0) {
                            if (nId) {
                                var arrs = res.data
                                self.scanList = [...arrs, ...self.scanList]
                                let id = res.data[0].logId
                                sessionStorage.setItem('logId',id)
                                self.scanList = self.scanList.slice(0,5)
                            } else {
                                self.scanList = res.data
                                let id = res.data[0].logId
                                sessionStorage.setItem('logId',id)
                            }
                        } else {
                            if(self.scanList.length == 0) {
                                sessionStorage.removeItem('logId')
                            }
                            // sessionStorage.removeItem('logId')
                        }

                    }
                })
            },
            fetchScanPeople() {
                let self = this
                scanStaticTotal({logType: 1}).then((res) => {
                    console.log("res", res);
                    if (res.code == 200) {
                        self.scanData = res.data
                    }
                })
            },
            fetchCheckPeople() {
                let self = this
                scanStaticTotal({logType: 2}).then((res) => {
                    console.log("res", res);
                    if (res.code == 200) {
                        self.checkData = res.data
                    }
                })
            },
            fetchBuyPeople() {
                let self = this
                scanStaticTotal({logType: 3}).then((res) => {
                    console.log("res", res);
                    if (res.code == 200) {
                        self.scanBuyData = res.data
                    }
                })
            },
            fetchNFCPeople() {
                let self = this
                scanStaticTotal({logType: 4}).then((res) => {
                    console.log("NFC的res", res);
                    if (res.code == 200) {
                        self.scanNfcData = res.data
                    }
                })
            },
            fetchMapData() {
                var dataList = []
                /*  [
                      {
                          "pro": "山西省",
                          "number": "7",
                          "time": "1647916496"
                      },
                      {
                          "pro": "北京市",
                          "number": "5",
                          "time": "1647916496"
                      },
                      {
                          "pro": "四川省",
                          "number": "3",
                          "time": "1647916496"
                      }
                  ]*/
                getTimeMapData().then((res) => {
                    console.log('小地图数据',res);
                    if (res.code == 200) {
                        let result = res.data
                        result.forEach((item,index) => {
                            var str = {
                                pro: item.province,
                                number: item.num,
                                time: item.time
                            }
                            dataList.push(str)
                        })
                        setTimeout(() => {
                            if (dataList.length > 0) {
                                this.dynamicPro = dataList[0].pro;
                            } else {
                                this.dynamicPro = ''
                            }

                            this.initEchartMap(dataList)
                        }, 100)

                    }
                })

            },
            stopClick() {
                clearInterval(this.tplTimes)
                this.tplTimes = null
            },
            initEchartMap(arrList) {
                var self = this
                this.stopClick()
                console.log('arrList', arrList);
                var len = arrList.length
                if (len == 0) {
                    arrList = []
                    this.tplTimes = null
                } else {
                    var arrs = []
                    var nLens = arrList.length - 1
                }

                if (null != this.tplTimes) {
                    clearInterval(this.tplTimes);
                }
                this.tplTimes = null

                var mapName = 'china'

                var geoCoordMap = {}

                var myMap = echarts.init(document.getElementById('echartsmap'))
                // echarts.registerMap('china', chinaData);
                myMap.off('click') // 这里很重要！！！
                /* 获取地图数据 */
                myMap.showLoading()

                var mapFeatures = this.$echarts.getMap(mapName).geoJson.features;
                myMap.hideLoading()

                console.log(mapFeatures);

                mapFeatures.forEach(function (v) {
                    // 地区名称
                    var name = v.properties.name
                    // 地区经纬度
                    geoCoordMap[name] = v.properties.cp
                })

                var pSite = 'top'
                var cNum = -1
                var hasClick = false;

                var nList = []

                if (arrList.length > 0) {
                    arrList.forEach((item, index) => {
                        var str = ''
                        if (item.pro.indexOf('市') != -1) {
                            str = item.pro.replace('市', '')
                        } else if (item.pro.indexOf('省') != -1) {
                            str = item.pro.replace('省', '')
                        }
                        if (item.pro == self.dynamicPro) {
                            self.hourIndex = index;
                        }
                        nList.push(str)
                    })
                    console.log('nlist', nList);
                }

                var options = {
                    background: '#fff',
                    color: ['#000', '#f7ee21'],
                    tooltip: {
                        // triggerOn: 'none', // 关闭默认的mouseover调用
                        trigger: 'item',
                        triggerOn: 'mousemove', // mousemove、click
                        padding: 1,
                        borderWidth: 1,
                        borderColor: '#ccc',
                        backgroundColor: '#c2ddff',
                        position: pSite,
                        formatter: function (p) {
                            var times = ''
                            var nums = 0
                            var newName = p.name

                            if (hasClick) {
                                times = arrList[self.hourIndex].time
                                nums = 0
                                //alert(newName)

                                if (arrList.length > 0) {
                                    arrList.forEach((item, index) => {
                                        var str = ''
                                        if (item.pro.indexOf(newName) != -1) {
                                            console.log(item.number)
                                            nums = item.number
                                        }
                                    })
                                } else {
                                    nums = 0
                                }

                            } else {
                                if (arrList.length > 0) {
                                    // var newTimes = formatTimes(Number(arrList[self.hourIndex].time))
                                    var newTimes = '---'
                                    if (!arrList[self.hourIndex].time) {
                                        newTimes = '---' // self.curentTimeTit()
                                    } else {
                                        newTimes = arrList[self.hourIndex].time
                                    }
                                    times = newTimes
                                    nums = arrList[self.hourIndex].number
                                    if (nums == 0) {
                                        nums = 0
                                    }
                                } else {
                                    times = self.curentTimeTit()
                                }
                            }

                            let context = ''
                            if (newName == '吉林' || newName == '辽宁' || newName == '黑龙江' || newName == '西藏' ||
                                newName == '新疆' || newName == '江苏' || newName == '上海') {
                                context = `
								<div style="padding:10px;width:auto">扫码地区: <span style="color: #0a77fb;">${newName}</span></div>
								<div style="width:auto;padding-left: 10px;">今日累计扫码: <span style="color: #c58d39;">${nums}次</span></div>
								<div style="padding:10px;width:auto">扫码时间：<div style="color: #0a77fb;">${times}</div></div>
								`
                            } else {
                                context = `
								<div style="padding:10px;width:auto">扫码地区：<span style="color: #0a77fb;">${newName}</span> 今日累计扫码: <span style="color: #c58d39;">${nums}次</span></div>
								<div style="padding:10px;width:auto">扫码时间：<span style="color: #0a77fb;">${times}</span></div>
								`
                            }
                            if (arrList.length > 0) {
                                hasClick = false
                                return context
                            }

                        }
                    },
                    geo: {
                        show: true,
                        map: 'china',
                        zoom: 1.1,
                        label: {
                            show: false,

                        },
                        emphasis: {
                            focus: 'self',
                            label: {
                                show: true,
                                color: '#fff'
                            },
                            itemStyle: {
                                color: '#fff'
                            }
                        },
                        roam: false, //地图设置不可拖拽，固定的
                        itemStyle: {
                            areaColor: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 800,
                                colorStops: [{
                                    offset: 0,
                                    color: '#0770d5', // 0% 处的颜色
                                },
                                    {
                                        offset: 0.5,
                                        color: '#f00', // 100% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#000', // 100% 处的颜色
                                    }
                                ],
                                global: false,
                            },
                            borderColor: '#a3be35',
                            borderWidth: 1,
                            shadowColor: '#b6b6b6',
                            shadowBlur: 20
                        }
                    },
                    series: [{
                        type: 'map',
                        map: 'china',
                        silent: true,
                        zoom: 1.1,
                        geoIndex: 1,
                        aspectScale: 0.75, //长宽比
                        showLegendSymbol: false, // 存在legend时显示
                        label: {
                            show: true,
                            fontSize: 12,
                            color: '#fff',
                            emphasis: {
                                show: false,
                                color: '#fff'
                            }
                        },
                        roam: false,
                        itemStyle: {
                            areaColor: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 800,
                                colorStops: [{
                                    offset: 0,
                                    color: '#3692ee', // 0% 处的颜色
                                },
                                    {
                                        offset: 0.5,
                                        color: '#c2d8ef', // 100% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#000', // 100% 处的颜色
                                    }
                                ],
                                global: true,
                            },
                            borderColor: '#99ffe2',
                            borderWidth: 1,

                        },
                        emphasis: {
                            label: {
                                show: true,
                                color: '#000'
                            },
                            itemStyle: {
                                areaColor: '#99ECF8',
                                color: "#fff"
                            }
                        }
                    }]
                }
                // console.log('arrList是：',arrList.length)
                if (arrList.length > 0) {
                    this.tplTimes = setInterval(function () {
                        setTimeout(() => {
                            hasClick = false
                        }, 1000)
                        if (cNum == '-1') {

                        } else {
                            myMap.dispatchAction({
                                type: "unselect",
                                seriesIndex: 0,
                                dataIndex: cNum
                            });
                            cNum = -1
                        }

                        myMap.dispatchAction({
                            type: "downplay",
                            seriesIndex: 0,
                        });
                        myMap.dispatchAction({
                            type: "highlight",
                            seriesIndex: 0,
                            name: nList[self.hourIndex]
                            // dataIndex: hourIndex
                        });
                        myMap.dispatchAction({
                            type: "showTip",
                            seriesIndex: 0,
                            name: nList[self.hourIndex]
                            // dataIndex: hourIndex
                        });

                        self.hourIndex++;
                        if (self.hourIndex > nList.length - 1) {
                            self.hourIndex = 0;
                        }
                    }, 2000);
                }

                myMap.setOption(options)

                // 鼠标点击
                myMap.on('click', function (e) {
                    if (arrList.length > 0) {
                        hasClick = true
                        if (null != self.tplTimes) {
                            clearInterval(self.tplTimes);
                            self.tplTimes = null
                        }

                        cNum = e.dataIndex

                        // console.log('省份下标是：' + cNum)
                        myMap.dispatchAction({
                            type: "downplay",
                            seriesIndex: 0,
                        });
                        myMap.dispatchAction({
                            type: "unselect",
                            seriesIndex: 0,
                            dataIndex: 0
                        });

                        /* myMap.dispatchAction({
                            type: "highlight",
                            seriesIndex: 0,
                            dataIndex: e.dataIndex
                        }); */
                        myMap.dispatchAction({
                            type: "showTip",
                            seriesIndex: 0,
                            dataIndex: e.dataIndex
                        });

                        if (self.tplTimes == null && hasClick == false) {
                            // hourIndex = 0
                            setTimeout(() => {
                                self.tplTimes = setInterval(function () {

                                    hasClick = false
                                    if (cNum == '-1') {

                                    } else {
                                        myMap.dispatchAction({
                                            type: "unselect",
                                            seriesIndex: 0,
                                            dataIndex: cNum
                                        });
                                        cNum = -1
                                    }

                                    myMap.dispatchAction({
                                        type: "downplay",
                                        seriesIndex: 0,
                                    });
                                    myMap.dispatchAction({
                                        type: "highlight",
                                        seriesIndex: 0,
                                        name: nList[self.hourIndex]
                                        // dataIndex: hourIndex
                                    });
                                    myMap.dispatchAction({
                                        type: "showTip",
                                        seriesIndex: 0,
                                        name: nList[self.hourIndex]
                                        // dataIndex: hourIndex
                                    });
                                    self.hourIndex++;
                                    if (self.hourIndex > nList.length - 1) {
                                        self.hourIndex = 0;
                                    }
                                }, 10000);
                            }, 2000)
                        } else {
                            clearInterval(self.tplTimes)
                        }

                    }
                })

            },
            curentTimeTit() {
                var now = new Date()

                var year = now.getFullYear() // 年
                var month = now.getMonth() + 1 // 月
                var day = now.getDate() // 日

                var hh = now.getHours() // 时
                var mm = now.getMinutes() // 分
                var ss = now.getSeconds() // 分

                var clock = year + '年'
                if (month < 10) {
                    clock += '0'
                }
                clock += month + '月'
                if (day < 10) {
                    clock += '0'
                }
                clock += day + '日' + ' '
                if (hh < 10) {
                    clock += '0'
                }
                clock += hh + ':'
                if (mm < 10) clock += '0'
                clock += mm + ':'
                if (ss < 10) clock += '0'
                clock += ss
                return (clock)
            },
            // 近7天扫码量
            fetchDaysData() {
                let params = {
                    dayNum: 7,
                }
                let v=this
                fetchLineChartData(params).then(res => {
                    let XData=res.data.scanList.map(item => item.time)
                    let scanData=res.data.scanList.map(item => item.num)
                    let verificationList=res.data.verificationList.map(item => item.num)
                    v.scanCount7Day=res.data.scanCount
                    v.verificationCount7Day=res.data.verificationCount
                    v.drawLine1(XData, scanData, verificationList);
                    console.log('1',XData, scanData, verificationList)
                })

            },
            // 近1年扫码量
            fetchYearData() {
                let params = {
                    monthNum: 12,
                }
                let v=this
                fetchLineChartData(params).then(res => {
                    let XData=res.data.scanList.map(item => item.time)
                    let scanData=res.data.scanList.map(item => item.num)
                    let verificationList=res.data.verificationList.map(item => item.num)
                    v.scanCount12Month=res.data.scanCount
                    v.verificationCount12Month=res.data.verificationCount
                    v.drawLine2(XData, scanData, verificationList);
                    console.log('2',XData, scanData, verificationList)
                })

            },
            // echarts
            drawLine1(XData,scanData,verificationListData) {
                let myChart = this.$echarts.init(document.getElementById('myChart1'))
                var option = {
                    backgroundColor:"#fff",
                    grid: {
                        top: 40,
                        left: 40,
                        bottom: 30
                        // containLabel: true
                    },
                    legend: {
                        show: false,
                        x: 'center',
                        y: '5',
                        itemWidth: 10,
                        itemHeight: 10,
                        textStyle: {
                            color: '#1bb4f6'
                        },
                        data: ['近7天扫码量','近7天验证量']
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    xAxis: [{
                        type: 'category',
                        // name: '地区',
                        boundaryGap: false,
                        // data: ['03-16', '03-17', '03-18','03-19','03-20','03-21','03-22'],
                        data: XData,
                        "axisLabel": {
                            "color": "#c0c3cd",//X轴文字
                            "fontSize": 12,
                            "interval": 0
                        },
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLine: {
                            show: false,
                            color:'#c0c3cd'
                        },
                        splitLine:{
                            show: false, // X轴线 颜色类型的修改
                            lineStyle: {
                                type: 'dashed',
                                color: '#292c38'
                            }
                        }
                    }],
                    yAxis: [{
                        type: 'value',
                        // name: '数量',
                        min: 0,
                        axisLabel: {
                            formatter: '{value}',
                            color: "#c0c3cd",//X轴文字
                            fontSize: 12,
                            interval: 0
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }],
                    series: [
                        {
                            name: "扫码量",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            // barWidth: 15, // 柱子宽度
                            symbolSize: 1,
                            zlevel: 3,
                            itemStyle:{
                                color:'#19a3df',
                                borderColor:"#a3c8d8",
                                // barBorderRadius: [5, 5, 0, 0], // 圆角（左上、右上、右下、左下）
                                // color: new modal.graphic.LinearGradient(0, 0, 0, 1, [{
                                //     offset: 0,
                                //     color: '#EDA01D'
                                // }, {
                                //     offset: 1,
                                //     color: '#EDA01D'
                                // }], false), // 渐变
                            },
                            lineStyle: {
                                normal: {
                                    width: 2,
                                    color: "#EDA01D",
                                }
                            },
                            // data: [30,28,50,48,50,36,58]
                            data: scanData,
                        },
                        {
                            name: '验证量',
                            type: 'line',
                            symbol: 'circle',
                            itemStyle: {
                                normal: {
                                    color: '#2FCEBC',
                                    lineStyle: {
                                        color: "#2FCEBC",
                                        width: 2
                                    },
                                }
                            },
                            // data: [20,18,45,40,30,20,40]
                            data: verificationListData,
                        }
                    ]
                };
                myChart.setOption(option)
                myChart.setOption(option)
                $(window).resize(function() { // 重置容器高宽
                    myChart.resize()
                })
            },
            // echarts
            drawLine2(XData,scanData,verificationListData) {
                let myChart = this.$echarts.init(document.getElementById('myChart2'))
                var option = {
                    backgroundColor:"#fff",
                    grid: {
                        top: 40,
                        left: 40,
                        bottom: 30
                        // containLabel: true
                    },
                    legend: {
                        show: false,
                        x: 'center',
                        y: '5',
                        itemWidth: 10,
                        itemHeight: 10,
                        textStyle: {
                            color: '#1bb4f6'
                        },
                        data: ['近7天扫码量','近7天验证量']
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    xAxis: [{
                        type: 'category',
                        // name: '地区',
                        boundaryGap: false,
                        data: XData,
                        // data: ['2021-04', '2021-05', '2021-06','2021-07','2021-08','2021-09','2021-10', '2021-11', '2021-12','2022-01', '2022-02','2022-03'],
                        axisLabel: {
                            color: "#c0c3cd",//X轴文字
                            fontSize: 12,
                            interval: this.viewWidth > 1180 ? 0 : 2,
                        },
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLine: {
                            show: false,
                            color:'#c0c3cd'
                        },
                        splitLine:{
                            show: false, // X轴线 颜色类型的修改
                            lineStyle: {
                                type: 'dashed',
                                color: '#292c38'
                            }
                        }
                    }],
                    yAxis: [{
                        type: 'value',
                        // name: '数量',
                        min: 0,
                        axisLabel: {
                            formatter: '{value}',
                            color: "#c0c3cd",//X轴文字
                            fontSize: 12,
                            interval: 0
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }],
                    series: [
                        {
                            name: "扫码量",
                            type: "line",
                            smooth: true,
                            showSymbol: true,
                            // barWidth: 15, // 柱子宽度
                            symbolSize: 1,
                            zlevel: 3,
                            itemStyle:{
                                color:'#19a3df',
                                borderColor:"#a3c8d8",
                                // barBorderRadius: [5, 5, 0, 0], // 圆角（左上、右上、右下、左下）
                                // color: new modal.graphic.LinearGradient(0, 0, 0, 1, [{
                                //     offset: 0,
                                //     color: '#EDA01D'
                                // }, {
                                //     offset: 1,
                                //     color: '#EDA01D'
                                // }], false), // 渐变
                            },
                            lineStyle: {
                                normal: {
                                    width: 2,
                                    color: "#EDA01D",
                                }
                            },
                            // data: [2000,2600,3000,2800,2500, 2000,2500,2600,2400,3000,3200, 3100]
                            data: scanData,
                        },
                        {
                            name: '验证量',
                            type: 'line',
                            symbol: 'circle',
                            itemStyle: {
                                normal: {
                                    color: '#2FCEBC',
                                    lineStyle: {
                                        color: "#2FCEBC",
                                        width: 2
                                    },
                                }
                            },
                            // data: [1800,2300,2600,2400,2000, 1900,2000,2400,2100,2080,3000, 2700]
                            data: verificationListData,
                        }
                    ]
                };
                myChart.setOption(option)
                $(window).resize(function() { // 重置容器高宽
                    myChart.resize()
                })
            },
            // 扫码统计弹窗
            handleScanDialog(type, data, fetchType, isToday) {
                this.$refs.scanDialog.openDialog(type, data, fetchType, isToday)
            },
            //扫码地域分布
            scanAreaData() {
                let params = {
                    num: 12,
                }
                let v=this
                getScanAreaData(params).then(res => {
                    console.log('扫码地域分布',res)
                    if (res.code == 200) {
                        var result = res.data
                        var arrs = []
                        result.forEach((item,index) => {
                            if (item.area) {
                                arrs.push(item)
                            }
                        } )
                        console.log('扫码地域arrs',arrs)
                        v.pieEcharts(arrs)
                    }
                })
            },
            pieEcharts(arrs) {
                var self = this
                var myPieChart = echarts.init(document.getElementById('pieBox'));
                // 定义颜色
                const colorList = [
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "#AD6BFF",
                        },
                        {
                            offset: 1,
                            color: "#FF6F86",
                        },
                    ]),
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "#FFBA68",
                        },
                        {
                            offset: 1,
                            color: "#FF8573",
                        },
                    ]),
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "#55A6FE",
                        },
                        {
                            offset: 1,
                            color: "#7BCFFF",
                        },
                    ]),
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "#42DBFF",
                        },
                        {
                            offset: 1,
                            color: "#89F4FF",
                        },
                    ]),
                    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "#00DEC0",
                        },
                        {
                            offset: 1,
                            color: "#38F7DB",
                        },
                    ]),
                ];

                // 定义数据
                let chartData = arrs.map((item, index) => {
                    return {
                        value: item.num,
                        name: item.area,
                        itemStyle: {
                            borderRadius: 100,
                            color: colorList[index],
                            // shadowColor: '#000',
                            // shadowBlur: 1,
                            // shadowOffsetY: 5,
                            // shadowOffsetX: 0,

                            // borderRadius: 100,
                            // shadowColor: '#2a2a34',
                            // shadowBlur: 5,
                            // shadowOffsetY: 0,
                            // shadowOffsetX: 0,
                            // borderColor: '#2a2a34',
                            // borderWidth: 2,
                        },
                        
                    }
                })

                let totalNum = chartData.reduce((per, cur) => per + cur.value, 0)

                // 定义options
                let options={
                    title: [
                            {
                                text: '扫码',
                                x: '30%',
                                y: '43%',
                                textAlign: 'center',
                                textStyle: {
                                    fontSize: '18',
                                    fontWeight: '700',
                                    color: '#333333',
                                    textAlign: 'center',
                                    // textShadowColor: '#000',
                                    // textShadowBlur: '1',
                                    // textShadowOffsetX: 2,
                                    // textShadowOffsetY: 2,
                                },
                            }, 
                            {
                                text: '地域分布',
                                left: '30%',
                                top: '52%',
                                textAlign: 'center',
                                textStyle: {
                                    fontSize: '18',
                                    fontWeight: '700',
                                    color: '#333',
                                    textAlign: 'center',
                                    // textShadowColor: '#000',
                                    // textShadowBlur: '1',
                                    // textShadowOffsetX: 1,
                                    // textShadowOffsetY: 1,
                                },
                            },
                        ],
                        tooltip: {
                            trigger: 'item'
                        },
                        color: colorList,
                        legend: {
                            orient: 'horizontal', //vertical horizontal
                            right: '1%',
                            top: '25%',
                            // bottom: 0,
                            // left: 0,
                            // show: false,
                            icon: 'circle',
                            width: 100,
                            itemWidth: 10,
                            itemHeight: 10,
                            data: chartData,
                            formatter:function(name){
                                // var oa = option.series[0].data;
                                for(var i = 0; i < chartData.length; i++){
                                    if(name==chartData[i].name){
                                        return "{name|" + name + "}" + ' ' + (chartData[i].value/totalNum * 100).toFixed(2) + '%';
                                    }
                                }
                            },

                            textStyle: {
                                lineHeight: 15,
                                rich: {
                                    name: {
                                        fontSize: 12,
                                        fontWeight: "500",
                                        color: "#939EB1",
                                        letterSpacing: '2px',
                                        padding: [0, 0, 0, 0],
                                    },
                                    val: {
                                        fontSize: 12,
                                        fontWeight: "500",
                                        color: "#333333",
                                        letterSpacing: '2px',
                                        padding: [0, 0, 0, 0],
                                    },
                                },
                            },
                        },
                        series: [{
                            type: 'pie',
                            roundCap: true,
                            radius: ['44%', '51%'],
                            center: ['30%', '50%'],
                            label: {
                                show: false,
                            },
                            labelLine: {
                                show: false,
                            },
                            data: chartData,
                        }]
                }

                myPieChart.setOption(options);
                window.addEventListener("resize", () => {
                    myPieChart.resize();
                });
            },
        },
        mounted() {
            let self = this
            this.fetchTimeData()
            this.fetchScanPeople()
            this.fetchCheckPeople()
            this.fetchBuyPeople()
            this.fetchNFCPeople()
            this.fetchDaysData()
            this.fetchYearData()
            this.getCompany()
            this.fetchMapData()
            this.scanAreaData()
            self.timer = setInterval(() => {
                self.fetchTimeData()
                self.fetchScanPeople()
                self.fetchCheckPeople()
                self.fetchBuyPeople()
                self.fetchNFCPeople()
                self.fetchDaysData()
                self.fetchYearData()
                self.fetchMapData()
                self.scanAreaData()
            }, 5000)

        },
        beforeDestroy() {
            clearInterval(this.timer)
        }
    }
</script>

<style scoped>
    .contentBox {
        width: 100%;
        height: auto;
        background-color: #fff;
    }

    .padLeft15 {
        padding-left: 15px;
    }

    .rowBox {
        width: 98%;
        height: auto;
        margin: auto;
    }

    .scansBox {
        width: 100%;
        height: auto;
        background-color: #f6f6f6;
        margin: 5px auto;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        padding: 20px 0;
        /*display: flex;
        justify-content: space-between;
        align-content: space-between;*/
    }

    .scanTimeBox {
        width: 45%;
        height: auto;
        float: left;
    }

    .mapShow {
        width: 27%;
        height: 320px;
        float: left;
        background-color: #fff;
    }
    .pieShowBox{
        width: 28%;
        height: 320px;
        float: left;
        background-color: #fff;
        position: relative;
    }
    .pieBox{
        width: 100%;
        height: 300px;
        position: relative;
        /* border: 1px solid #f00; */
    }
    .pieTitle{
        text-align: center;
        position: absolute;
        font-weight: bold;
        left: 15%;
        top: 42%;
    }
    .pieTitle span {
        display: block;
        width: 100%;
    }
    .detailTitle{
        font-size: 16px;
        color: #20a0ff;
        float: right;
        margin-top: 30px;
        position: relative;
        z-index: 20;
        margin-right: 1%;
        /* margin-right: 30px; */
    }

    .pieMore{
        font-size: 16px;
        color: #20a0ff;
        float: right;
        position: relative;
        z-index: 20;
        top: 0px;
        cursor: pointer;
        margin-right: 3%;
    }

    .ibox {
        clear: both;
        margin-bottom: 20px;
        margin-top: 0;
        padding: 0;
        background: #fff;
        height: auto;
        position: relative;
    }

    .titForTips {
        font-size: 18px;
        height: 40px;
        line-height: 30px;
        padding-top: 9px;
        position: relative;
        z-index: 2;
    }
    .titForTips::after{
        content: "";
        display: block;
        width: 80px;
        height: 11px;
        background: rgba(226, 234, 255, 0.5);
        position: absolute;
        z-index: -1;
        left: 25px;
        bottom: 0;
    }

    .topsBox {
        width: 98%;
        height: 40px;
        margin: 5px auto;
        background-color: #F3F6FA;
        border-radius: 30px;
    }

    .topItems {
        float: left;
        line-height: 40px;
        color: #000;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px;
        line-height: 40px;
    }

    .liItems {
        float: left;
        /* height: 3rem;
        line-height: 3rem; */
        color: #000;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
    }

    .top_type {
        width: 10%;
    }

    .top_add {
        width: 27%;
    }

    .top_pro {
        width: 20%;
    }

    .top_iden {
        width: 20%;
    }

    .top_time {
        width: 21%;
    }

    .avatarImg {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        vertical-align: middle;
    }

    .timeShow {
        font-size: 12px;
        background-color: transparent;
        color: #fff;
        border-radius: 4px;
        height: 25px;
        padding: 0 6px;
        line-height: 25px;
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .timeShow.grey{
        background-color: #EAEFFF;
        color: #4A7AF0;
    }
    .timeShow.blue{
        background-color: #8EABFF;
        color: #fff;
    }
     .timeShow.orange{
        background-color: #FFAB52;
        color: #fff;
    }
    ul, li {
        list-style: none;
    }

    #news_ul {
        width: 97%;
        margin: 5px auto;
    }

    ul .iden_li {
        /* height: 3rem;
        line-height: 3rem; */
        height: 50px;
        line-height: 50px;
        /* margin-bottom: 5px; */
        border-bottom: 1px solid #eee;
        font-size: 13px;
    }

    .scanItem {
        width: 32%;
        height: 230px;
        background-color: #ffffff;
        float: left;
        position: relative;
        padding-top: 35px;
    }
    .newsItems{
        width: 24%;
    }

    .dataTipsBox {
        position: absolute;
        left: 0;
        top: 0;
    }

    .tipBackImg {
        /* width: 7rem;
        height: auto; */
        width: 112px;
        height: 30px;
        display: block;
        float: left;
    }

    .tipsTitles {
        position: absolute;
        /* left: 1.3rem;
        top: 0.26rem; */
        left: 20px;
        top: 3px;
        color: #fff;
    }

    .codeItem {
        width: 46%;
        height: 90px;
        float: left;
        margin-right: 1%;
        margin-left: 1%;
        cursor: pointer;
    }
    .scanItem1 .codeItem:hover,  .scanItem4 .codeItem:hover {
        background-color: #fefbf6;
    }
    .scanItem2 .codeItem:hover {
        background-color: #f5faf4;
    }
    .scanItem3 .codeItem:hover {
        background-color: #f5f9fb;
    }

    .forLines {
        width: 2px;
        height: 30px;
        border-left: 1px dashed #e8e8e8;
        float: left;
        margin-top: 35px;
    }

    .codeNumber {
        /* font-size: 1.75rem;
        line-height: 3.2rem; */
        font-size: 25px;
        line-height: 55px;
        text-align: center;
        margin-top: 5px;
    }

    .codeTitle {
        font-size: 16px;
        color: #aaa;
        /* line-height: 1.8rem; */
        line-height: 25px;
        text-align: center;
    }

    .showActives {
        color: #4C95F8;
    }

    .yell_Color {
        color: #FF8438;
    }

    .gre_color {
        color: #08A89B;
    }

    .nfc_color {
        color: #05c944;
    }
    .echartBox{
        /* border: 1px solid #f00; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        padding: 0 0 30px;
        background-color: #fff;
    }
    .echartWrap{
        width: 49%;
        /* border: 1px solid #0f0; */
        background: #fff;
        margin-top: 20px;
        display: border-box;
        padding-left: 10px;
        padding-bottom: 15px;
        overflow: hidden;
    }
    .lengeBox{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        padding-top: 20px;
    }
    .lengeBox .linesImg{
        width: 50px;
        height: 50px;
        margin-right: 15px;
    }
    .linesForBox{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        /* font-size: 0.96rem; */
        font-size: 14px;
        padding-right: 30px;
    }
    .lengeBox .scanShows .newsNumss{
        /* font-size: 1.42rem; */
        font-size: 24px;
        color: #EDA01D;
        line-height: 30px;
        font-weight: 700;
    }
    .linesBox{
        margin-top: 5px;
        height: 250px;
        /* border: 1px solid #F00; */
    }
    @media screen and (max-width: 1180px){
        .scanTimeBox {
            width: 100%;
            height: auto;
            float: left;
        }

        .mapShow {
            width: 50%;
            height: 320px;
            float: left;
            background-color: #fff;
        }
        #echartsmap{
            margin-top: 15px !important;
        }
        .pieShowBox{
            width: 47%;
            height: 320px;
            float: left;
            background-color: #fff;
            position: relative;
        }
        .codeTitle{
            font-size: 12px;
        }
        .newsTitle{
            font-size: 12px;
        }
    }

</style>


